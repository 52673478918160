class TwitterFeed {
  constructor() {
    const isFirefox = typeof InstallTrigger !== 'undefined';

    if (document.getElementById('feed') === null) {
      return;
    }

    if (isFirefox) {
      document.getElementById('feed').addEventListener('load', () => {
        this.constructor.styleTwitterFeed();
      });
    } else {
      setTimeout(() => {
        this.constructor.styleTwitterFeed();
      }, 250);
    }
  }

  static styleTwitterFeed() {
    window.twttr.ready(
      (twttr) => {
        twttr.events.bind(
          'rendered',
          (event) => {
            const css = '.timeline-Tweet-text {font-size: 14px !important; font-weight: 300 !important} ';
            const { head } = event.target.contentWindow.document;
            const style = document.createElement('style');
            head.appendChild(style);
            style.type = 'text/css';
            style.appendChild(document.createTextNode(css));
          },
        );
      },
    );
  }
}

export default TwitterFeed;
