import stickyTableHeaders from 'sticky-table-headers';

class StickyTableHeader {
  constructor() {
    window.addEventListener('load', this.run);
    window.addEventListener('resize', this.run);
    window.addEventListener('scroll', this.run);
  }

  /* eslint class-methods-use-this: "off" */
  run() {
    const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

    if (vw >= 960) {
      $('.bim-table').stickyTableHeaders({ fixedOffset: $('.page-header') });
      $(window).trigger('scroll.stickyTableHeaders');
    } else {
      $('.bim-table').stickyTableHeaders('destroy');
    }
  }
}

export default StickyTableHeader;
