import Helpers from '../../javascripts/lib/helpers';
/* eslint class-methods-use-this: "off" */

class InPageNavigation {
  constructor() {
    this.theBreadcrumb = document.querySelector('.breadcrumbs');
    this.theContent = document.querySelector('.page-content');

    this.init();
  }

  init() {
    this.build();
    this.move();

    window.addEventListener('resize', () => {
      this.move();
    });

    window.addEventListener('load', () => {
      this.resizeToggle();
    });
  }

  build() {
    const navs = document.querySelectorAll('.widget--navigation nav');
    Helpers.forEach(navs, (el) => {
      el.className = 'js-sub-navigation';
      const subNavControl = document.createElement('span');

      subNavControl.className = 'js-subnav-toggle';
      subNavControl.innerHTML = '<svg role="presentation"><use class="plus" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg--plus"></use><use class="minus" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg--minus"></use></svg>';

      el.parentNode.insertBefore(subNavControl, el.parentNode.firstChild);
    });
  }

  show() {
    Helpers.forEach(document.querySelectorAll('.widget--navigation .widget__title'), (el) => {
      el.setAttribute('aria-expanded', 'true');
    });
    Helpers.forEach(document.querySelectorAll('.widget--navigation'), (el) => {
      el.querySelector('ul').setAttribute('data-state', 'active');
    });
  }

  hide() {
    if (document.querySelector('.widget--navigation .widget__title') !== null) {
      Helpers.forEach(document.querySelectorAll('.widget--navigation .widget__title'), (el) => {
        el.setAttribute('aria-expanded', 'false');
      });
      Helpers.forEach(document.querySelectorAll('.widget--navigation'), (el) => {
        el.querySelector('ul').setAttribute('data-state', '');
      });
    }
  }

  move() {
    let navTitle = document.querySelector('.widget--navigation .widget__title');
    let navWidget = document.querySelector('.widget--navigation');

    if (navWidget !== null) {
      const newNav = document.createElement('nav');

      newNav.className = 'widget widget--navigation widget--cloned';
      if (navTitle !== null) {
        newNav.innerHTML = navWidget.innerHTML;
        navTitle = newNav.querySelector('.widget__title');
        navTitle.setAttribute('aria-expanded', 'false');
      } else {
        newNav.innerHTML = navWidget.innerHTML;
        navTitle = newNav.querySelector('.widget__title');
      }

      navWidget = newNav;
      navWidget.querySelector('ul').setAttribute('data-state', '');

      const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
      if (vw < 959) {
        if (!document.querySelector('.page-content > .widget--navigation')) {
          this.remove();
          this.theContent.insertBefore(newNav, this.theBreadcrumb);
          this.navToggle();
          this.toggleActive();

          newNav.innerHTML = `<h3 class="widget__title">${document.querySelector('.primary-navigation a.active').innerHTML}<span><svg role="presentation"><use class="menu" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg--menu"></use><use class="close" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg--close"></use></svg></span></h3>${navWidget.innerHTML}`;

          newNav.querySelector('.widget__title').addEventListener('click', (e) => {
            if (e.target.getAttribute('aria-expanded') === 'true') {
              e.target.setAttribute('aria-expanded', 'false');
              newNav.querySelector('ul').setAttribute('data-state', 'hidden');
            } else {
              e.target.setAttribute('aria-expanded', 'true');
              newNav.querySelector('ul').setAttribute('data-state', 'active');
            }
            this.resizeToggle();
            this.subNavToggle();
          });
        }
      } else {
        this.remove();
        this.navToggle();
        this.subNavToggle();
      }
      this.resizeToggle();
      this.toggleActive();
    }
  }

  resizeToggle() {
    Helpers.forEach(document.querySelectorAll('.widget--navigation li'), (el) => {
      const anchor = el.querySelector('a');
      const toggle = el.querySelector('.js-subnav-toggle');

      if (toggle) {
        toggle.style.height = `${anchor.clientHeight}px`;
      }
    });
  }

  navToggle() {
    const navTitle = document.querySelectorAll('.widget--navigation .widget__title');
    if (navTitle !== null) {
      Helpers.forEach(document.querySelectorAll('.widget--navigation .widget__title'), (el) => {
        el.addEventListener('click', (e) => {
          if (this.getAttribute('aria-expanded') === 'true') {
            this.hide();
          } else {
            this.show();
          }
          this.resizeToggle();

          e.preventDefault();
        });
      });
    }
  }

  subNavToggle() {
    Helpers.forEach(document.querySelectorAll('.js-subnav-toggle'), (el) => {
      el.addEventListener('click', (e) => {
        if (el.getAttribute('aria-expanded') !== 'true') {
          el.setAttribute('aria-expanded', 'true');
          el.parentNode.querySelector('.js-sub-navigation').setAttribute('data-state', 'active');
          this.resizeToggle();
        } else {
          el.setAttribute('aria-expanded', 'false');
          el.parentNode.querySelector('.js-sub-navigation').setAttribute('data-state', '');
          this.resizeToggle();
        }
        e.preventDefault();
      });
    });
  }

  remove() {
    const clonedNav = document.querySelector('.widget--navigation.widget--cloned');
    if (clonedNav) {
      clonedNav.parentNode.removeChild(clonedNav);
    }
  }

  toggleActive() {
    Helpers.forEach(document.querySelectorAll('.widget--navigation li.active'), (el) => {
      const span = el.querySelector('span');
      const subNav = el.querySelector('.js-sub-navigation');

      if (span && subNav) {
        span.setAttribute('aria-expanded', 'true');
        subNav.setAttribute('data-state', 'active');
      }
    });
  }
}

export default InPageNavigation;
