import Helpers from '../lib/helpers';

class FakeUploadInput {
  constructor() {
    const realInputs = document.querySelectorAll('[data-upload-real-input]');

    if (realInputs.length <= 0) { return; }

    Helpers.forEach(realInputs, (input, _) => {
      input.addEventListener('change', (e) => {
        const fauxInput = e.target
          .parentNode
          .parentNode
          .querySelector('[data-upload-fake-input]');

        fauxInput.value = input.value.split(/(\\|\/)/g).pop();
      });
    });
  }
}

export default FakeUploadInput;
