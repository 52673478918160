import Helpers from '../lib/helpers';

class ProductSelector {
  constructor() {
    /**
     * Redirects
     */
    // 1. Define the productRangeSelector by grabbing the <select>
    const productRangeSelector = document.querySelector('[data-product-range-selector]');
    // 2. If the productRangeSelector exists
    if (productRangeSelector) {
      // 1. onChange redirect the user to the appropriate place
      productRangeSelector.addEventListener('change', () => {
        window.location.href = `/product_selectors/${productRangeSelector.value}`;
      });
    }

    /**
     * Lintels enquiry forms
     */
    // 1. Grab all the forms on the page
    const enquiryForms = document.querySelectorAll('[data-lintel-enquiry-form]');
    // 2. Loop through each form
    Helpers.forEach(enquiryForms, (el) => {
      // 1. Hide the form
      el.style.display = 'none';

      // 2. Get an identifier for this particular form
      const { productFormId } = el.dataset;

      // 3. Create a button before each form
      const formToggle = document.createElement('button');
      formToggle.innerHTML = 'Enquire about this product';
      formToggle.setAttribute('class', 'btn btn--brand btn--padded');
      formToggle.setAttribute('data-for-product-form-id', productFormId);

      // 4. Insert the button before the form
      ProductSelector.insertAfter(formToggle, el);

      // 5. Add a click event to show the form
      document.addEventListener('click', (e) => {
        if (e.target && e.target.dataset.forProductFormId === productFormId) {
          const form = document.querySelector(`[data-product-form-id="${productFormId}"`);
          form.style.display = 'block';
          Helpers.remove(e.target);
        }
      });
    });

    this.constructor.moveSelectorControls();
    window.addEventListener('resize', this.constructor.moveSelectorControls);
  }

  static moveSelectorControls() {
    // 1. Grab the controls
    const controls = document.querySelector('[data-product-selector-controls]');
    const primaryContent = document.querySelector('.primary-content');
    if (controls && primaryContent) {
      // Get vw.  This is a replacement for the original 'theMQ' implementation.
      const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

      // 2. If on mobile/tablet, move them to the top of the page
      if (vw < 768) {
        // 1. Insert the sidebar before the primary content
        ProductSelector.insertBefore(controls, primaryContent);

        // 2. Remove the old one
        Helpers.remove(controls);

        // 3. Add a toggle
        const toggle = document.createElement('button');
        toggle.innerHTML = 'Refine your search';
        toggle.setAttribute('data-sidebar-toggle', '');
        Helpers.addClass(toggle, 'btn');
        Helpers.addClass(toggle, 'btn--brand');
        Helpers.addClass(toggle, 'btn--selector-toggle');

        if (!document.querySelector('[data-sidebar-toggle]')) {
          ProductSelector.insertBefore(toggle, document.querySelector('[data-product-selector-controls]'));

          document.querySelector('[data-sidebar-toggle]').addEventListener('click', () => {
            Helpers.toggleClass(document.querySelector('[data-product-selector-controls]'), 'is-hidden');
          });
        }

        // 4. Hide the sidebar
        Helpers.addClass(document.querySelector('[data-product-selector-controls]'), 'is-hidden');
      } else {
        // 3. Otherwise, we're on desktop and should put them on the left

        // 1. Insert the sidebar before the primary content
        ProductSelector.insertBefore(primaryContent, controls);

        // 2. Remove the old one
        Helpers.remove(primaryContent);

        // 3. Show the sidebar if it is hidden
        Helpers.removeClass(document.querySelector('[data-product-selector-controls]'), 'is-hidden');

        // 4. Remove the toggle
        if (document.querySelector('[data-sidebar-toggle]')) {
          Helpers.remove(document.querySelector('[data-sidebar-toggle]'));
        }
      }
    }
  }

  static insertBefore(newNode, referenceNode) {
    referenceNode.insertAdjacentHTML('beforebegin', newNode.outerHTML);
  }

  static insertAfter(newNode, referenceNode) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
  }
}

export default ProductSelector;
