import 'vanilla-cookieconsent/src/cookieconsent';
import 'vanilla-cookieconsent/dist/cookieconsent.css';

/*
NOTES:

'CookieConsent' is the name of the plugin package used.
'CookieControl' is the name of our wrapping implementation (this class).
This naming difference is intentional, to keep clear where cookieConsent stops, and our code starts.

When editing permission settings here, you will also need to consider (and possibly amend) the
Ruby counterpart (cookie_control.rb).
*/

class CookieControl {
  constructor({
    cookieSettingsLinksSelector,
    translations,
    revision,
    cookieExpiration,
    delay,
  }) {
    const cookieConsent = window.initCookieConsent();
    const t = translations.cookie_consent; // Concise version, an alias if you like.

    function decodeHTML(html) {
      const txt = document.createElement('textarea');
      txt.innerHTML = html;
      return txt.value;
    }

    function updateGTMConsent() {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event: 'client-consent-update' });
    }

    cookieConsent.run({
      autoclear_cookies: true,
      remove_cookie_tables: true,
      force_consent: true,
      hide_from_bots: true,
      use_rfc_cookie: true,
      page_scripts: true,
      cookie_expiration: cookieExpiration, // days
      delay, // ms
      revision, // default of 0
      onFirstAction() {
        CookieControl.log('You completed your first action of cookie preferences.');
        updateGTMConsent();
      },
      onChange() {
        CookieControl.log('You just changed your preferences');
        updateGTMConsent();
      },
      current_lang: 'en', // Cosmetic (in console).  We're passing in translations to same one lang.
      languages: {
        en: {
          consent_modal: {
            title: t.consent_modal.title,
            description: `${decodeHTML(t.consent_modal.description)} {{revision_message}}`,
            revision_message: decodeHTML(t.consent_modal.revision_message),
            primary_btn: {
              text: t.consent_modal.accept_all,
              role: 'accept_all', // 'accept_selected' or 'accept_all'
            },
            secondary_btn: {
              text: t.consent_modal.manage_settings,
              role: 'settings', // 'settings' or 'accept_necessary'
            },
          },
          settings_modal: {
            title: t.settings_modal.title,
            save_settings_btn: t.settings_modal.save_settings_btn,
            accept_all_btn: t.settings_modal.accept_all_btn,
            reject_all_btn: t.settings_modal.reject_all_btn, // optional, [v.2.5.0 +]
            close_btn_label: t.settings_modal.close_btn_label,
            cookie_table_headers: [
              { col1: 'cookie name' }, // Need to keep this in, even though not displayed, so autoclear works.
            ],
            blocks: [
              {
                title: t.settings_modal.blocks.intro.title,
                description: decodeHTML(t.settings_modal.blocks.intro.description),
              },
              {
                title: t.settings_modal.blocks.necessary.title,
                description: t.settings_modal.blocks.necessary.description,
                toggle: {
                  value: 'necessary',
                  enabled: true,
                  readonly: true,
                },
                cookie_table: [
                  { col1: '_cms_session' },
                  { col1: 'user_credentials' },
                  { col1: '__cf_bm' },
                  { col1: '_GRECAPTURE' },
                ],
              },
              {
                title: t.settings_modal.blocks.functional.title,
                description: t.settings_modal.blocks.functional.description,
                toggle: {
                  value: 'functional',
                  enabled: false,
                  readonly: false,
                },
                cookie_table: [
                  { col1: 'CONSENT' },
                  { col1: 'YSC' },
                  { col1: '_abexps' },
                  { col1: '__pdst' },
                  { col1: 'vuid' },
                  { col1: 'player' },
                  { col1: 'personalization_id' },
                  { col1: '_twitter_sess' },
                  { col1: 'guest_id' },
                  { col1: '_ga' },
                  { col1: '_gat' },
                  { col1: '_gid' },
                ],
              },
              {
                title: t.settings_modal.blocks.analytics.title,
                description: t.settings_modal.blocks.analytics.description,
                toggle: {
                  value: 'analytics',
                  enabled: false,
                  readonly: false,
                },
                cookie_table: [
                  { col1: '_ga' },
                  { col1: '_gid' },
                  { col1: '^_gat_UA-', is_regex: true },
                  { col1: '^_dc_gtm_UA-', is_regex: true },
                ],
              },
              {
                title: t.settings_modal.blocks.marketing.title,
                description: t.settings_modal.blocks.marketing.description,
                toggle: {
                  value: 'marketing',
                  enabled: false,
                  readonly: false,
                },
                cookie_table: [
                  { col1: 'anconp' },

                  { col1: 'li_gc' },
                  { col1: 'li_mc' },
                  { col1: 'lang' },
                  { col1: 'lidc' },
                  { col1: 'liap' },
                  { col1: 'bcookie' },
                  { col1: 'AnalyticsSyncHistory' },
                  { col1: 'UserMatchHistory' },
                  { col1: 'DSID' },
                  { col1: 'id' },
                ],
              },
            ],
          },
        },
      },
    });

    // Add items to 'this' - so they may be accessed via this instance from elsewhere.
    // Haven't done this earlier, just to avoid a bunch of 'this.' prefixes in the above code.
    this.cookieConsent = cookieConsent;
    this.translations = translations;

    /*
    Note:  The cookieConsent settings modal might need to be opened before they've interacted
    with the cookieConsent at all (i.e. before onAccept has ever run), so need to place the
    cookie settings link code here (outside of onAccept).

    Note:  Setting up cookie setting link event listeners here, after cookieConsent has been
    initialised - so the initial consentModal has been rendered - so we can add an event listener
    onto a link in the cookieConsent consent modal description to open settings (if present).
    */

    if (cookieSettingsLinksSelector) {
      this.cookieSettingsLinks = document.querySelectorAll(cookieSettingsLinksSelector);
    }

    if (this.cookieSettingsLinks) {
      this.cookieSettingsLinks.forEach((link) => {
        this.addSettingsLinkEventListener(link);
      });
    }

    /*
    Further Note:  The cookieConsent settings modal might need to be opened by a link which is
    injected into the HTML - e.g. by PopupBox for Webinars.  Those links will not have been present
    when the above code was run to set up link event listeners, so we now set up a mutation observer
    which will look for any of these links being added into the DOM, and add event listeners to
    them:
    */
    const target = document.body;
    const config = { attributes: true, childList: true, characterData: true };
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        mutation.addedNodes.forEach((node) => {
          if (node instanceof HTMLElement) {
            const cookieSettingsLinks = node.querySelectorAll(cookieSettingsLinksSelector);
            if (cookieSettingsLinks) {
              cookieSettingsLinks.forEach((link) => {
                this.addSettingsLinkEventListener(link);
              });
            }
          }
        });
      });
    });
    observer.observe(target, config);
  }

  twitterCookiesAllowed() {
    return this.cookieConsent.allowedCategory('functional');
  }

  videoCookiesAllowed() {
    return this.cookieConsent.allowedCategory('functional');
  }

  addSettingsLinkEventListener(link) {
    link.addEventListener(
      'click',
      (e) => {
        e.preventDefault();
        this.cookieConsent.showSettings();
      },
    );
  }

  static log(...args) { /* eslint-disable-line no-unused-vars */
    // console.log(...args); /* eslint-disable-line no-console */
  }
}

export default CookieControl;
