/* eslint no-console:0 */
/* eslint no-new: "off" */

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// console.log('Hello World from Webpacker');

import Rails from '@rails/ujs';
// import Turbolinks from "turbolinks"  // Not in use on this site
// import * as ActiveStorage from "@rails/activestorage"  // Not in use on this site
// import "channels"  // Not in use on this site

import Helpers from '../../javascripts/lib/helpers';

import BimObjects from '../../javascripts/application/bim-objects';
import ChatButton from '../../javascripts/application/chat-button';
import CookieControl from '../../javascripts/application/cookie-control';
import ExternalContacts from '../../javascripts/application/external-contacts';
import FakeUploadInput from '../../javascripts/application/fake-upload-input';
import FeedbackTab from '../../javascripts/application/feedback-tab';
import GallerySlider from '../../javascripts/application/gallery-slider';
import InPageNavigation from '../../javascripts/application/in-page-navigation';
import Navigation from '../../javascripts/application/navigation';
import PopupBox from '../../javascripts/application/popup-box';
import ProductSelector from '../../javascripts/application/product-selector';
import ProgressiveProfiling from '../../javascripts/application/progressive-profiling';
import Scrolling from '../../javascripts/application/scrolling';
import SearchBox from '../../javascripts/application/search-box';
import StickyHeader from '../../javascripts/application/sticky-header';
import StickyTableHeader from '../../javascripts/application/sticky-table-header';
import TrimSummaryContents from '../../javascripts/application/trim-summary-contents';
import TwitterFeed from '../../javascripts/application/twitter-feed';
import Webinars from '../../javascripts/application/webinars';
import WindpostForm from '../../javascripts/application/windpost-form';

import '../../stylesheets/application/application.scss';

import lazySizes from 'lazysizes';

// Lazysizes IE11 polyfill for object-fit
if (!('object-fit' in document.createElement('a').style)) {
  require('lazysizes/plugins/object-fit/ls.object-fit');
}

// Note:  $ and jQuery are globally imported and defined via config/webpack/environment.js

Rails.start();
// Turbolinks.start();  // Not in use on this site
// ActiveStorage.start();  // Not in use on this site

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
const images = require.context('../../images/application', true);
const imagePath = (name) => images(name, true);

Helpers.ready(() => {
  // Replacement for Modernizr's no-js/js class change, without needing Modernizr:
  const htmlTag = document.documentElement;
  htmlTag.className = htmlTag.className.replace('no-js', 'js');

  const cookieControl = new CookieControl({
    cookieSettingsLinksSelector: '[data-cookie-settings]',
    googleAnalyticsKey: window.cookieControlConfig.googleAnalyticsKey,
    translations: window.cookieControlConfig.translations,
    revision: window.cookieControlConfig.revision,
    cookieExpiration: window.cookieControlConfig.cookieExpiration,
    delay: 500,
  });

  new ChatButton();
  new FakeUploadInput();
  new GallerySlider();
  new InPageNavigation();
  new Navigation();
  new ProductSelector();
  new Scrolling();
  new SearchBox();
  new StickyHeader();
  new StickyTableHeader();
  new TrimSummaryContents();
  new Webinars();

  if (cookieControl.twitterCookiesAllowed()) {
    new TwitterFeed();
  }

  if (window.alternativePagesPopup !== undefined) {
    const popupBox = new PopupBox();
    popupBox.init(window.alternativePagesPopup);
  }

  if (document.querySelector('[data-js="windpost"]')) {
    new WindpostForm();
  }

  if (document.querySelector('[data-regions]')) {
    new ExternalContacts();
  }

  // Sharing icon shown at top right of content area.
  (new PopupBox()).init({
    template: '/share-box.html',
    selector: '[data-js="share-btn"]',
    styleClasses: '',
  });

  // Orange sidepanel box on e.g. project pages.
  (new PopupBox()).init({
    template: `${window.location}/new_contact`,
    selector: '[rel="need-more-information"]',
    styleClasses: 'popup-box--more-info',
  });

  // Feedback tab, and its popup when clicked.
  if (document.querySelector('[data-feedback-form]')) {
    new FeedbackTab();
    (new PopupBox()).init({
      template: '/feedback_submissions/new',
      selector: '[data-feedback-form]',
      styleClasses: 'popup-box--feedback',
    });
  }

  // Progressive Profiling.
  const progressiveProfiling = new ProgressiveProfiling();
  progressiveProfiling.init();

  new BimObjects(progressiveProfiling);
});
