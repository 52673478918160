class Navigation {
  constructor() {
    this.config = {
      selector: document.querySelector('[data-js="primary-navigation"]'),
      toggle: document.querySelector('[data-js="menu-toggle"]'),
    };

    this.visible = false;
    this.className = 'secondary';

    this.init();
  }

  init() {
    this.config.toggle.addEventListener('click', (e) => {
      if (this.visible) {
        this.hide();
      } else {
        this.show();
      }

      e.preventDefault();
    });

    // if viewport is resized to greater than 960px hide the nav
    window.addEventListener('resize', () => {
      const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

      if (vw > 959) {
        this.hide();
      }
    });
  }

  show() {
    this.config.selector.setAttribute('data-state', 'active');
    this.config.toggle.setAttribute('aria-expanded', 'true');
    this.visible = true;
  }

  hide() {
    this.config.selector.setAttribute('data-state', '');
    this.config.toggle.setAttribute('aria-expanded', 'false');
    this.visible = false;
  }
}

export default Navigation;
