import Helpers from '../../javascripts/lib/helpers';
/* eslint class-methods-use-this: "off" */

class ProgressiveProfiling {
  constructor() {
    this.visibleClassName = 'is-visible';
    this.isVisible = false;

    // setup some config items that can be overwritten later
    this.config = {
      template: '/progressive-profiling.html',
      selector: '[data-js="progressive-profile"]', // the selector that should kick off the overlay
      closeSelector: '[data-js="popup-box-close"]',
      skipSelector: '[data-js="skip-to-download"]',
      styleClasses: '',
    };

    const fileExtensionFromURL = (urlWithoutQueryString) => {
      if (urlWithoutQueryString.lastIndexOf('.') > -1) {
        return urlWithoutQueryString.slice(
          urlWithoutQueryString.lastIndexOf('.') + 1,
        ).toLowerCase();
      }
      return 'unknown';
    };

    // gtmPageView is used by this file, but also by the success.js.erb partial, so this function
    // is attached to the window so its available there also.
    window.gtmPageView = (url, title, _source) => {
      window.dataLayer = (window.dataLayer || []);

      const urlWithoutQueryString = url.split('?')[0];

      window.dataLayer.push({
        event: 'VirtualPageview',
        virtualPageURL: urlWithoutQueryString,
        virtualPageTitle: title,
        fileExtension: fileExtensionFromURL(urlWithoutQueryString),
      });

      // console.log('gtm done', _source);
    };
  }

  init() {
    const profilingTriggers = document.querySelectorAll('[data-js="progressive-profile"]');
    Helpers.forEach(profilingTriggers, (el) => {
      el.addEventListener('click', (e) => {
        // 1. Prevent the default behaviour
        e.preventDefault();
        e.stopPropagation();

        // 2. Store the intended URL
        const intendedUrl = e.target.href;
        // console.log('intendedUrl: ', intendedUrl);

        // 3. Start building the popup and pass the intended URL
        this.show(intendedUrl);
      });
    });

    // Close the popup box when user hits `esc` key
    document.onkeydown = (evt) => {
      evt = evt || window.event;
      if (evt.keyCode == 27) {
        this.hide();
      }
    };

    // The chunk below looks like repetition of whats already below - but is used
    // for when have a direct download URL - and no chance to show a modal.
    // Pre-webpack this used to be loose in main.js.
    if (document.querySelector('.progressive-profiling--no-js')) {
      this.toggleAdditionalFields(document.querySelector('.progressive-profiling--no-js'));
      Helpers.forEach(document.querySelectorAll('[data-js="skip-to-download"]'), (el) => {
        el.addEventListener('click', (e) => {
          e.preventDefault();
          const downloadUrl = e.target.href;
          this.skipToDownload(
            downloadUrl,
            e.target.getAttribute('data-skip-title'),
            e.target.getAttribute('data-skip-message'),
            e.target.getAttribute('data-skip-link'),
          );
        });
      });
    }
  }

  show(intendedUrl) {
    // 1. Grab the download link
    Helpers.getFileContents(intendedUrl, (response) => {
      // 1. Grab the JSON and parse it
      const rawJson = response;
      const json = JSON.parse(rawJson);

      // 2. Store values from the JSON response
      const detailsRequired = json.ask_for_details;
      const profileIsBlank = json.profile_blank;
      const completeProfileRequired = json.must_ask_for_completed_profile;

      let url;

      // 3. If the user needs to provide some details store the form URL
      if (detailsRequired) {
        url = json.profile_form_url;
      } else {
        // 4. Otherwise, store the download URL with token
        url = json.download_url;
      }

      // 5. If the user needs to provide details show the popup
      if (detailsRequired) {
        // 1. Grab the content of `url`
        Helpers.getFileContents(url, (resp) => {
          // 1. Create a container for the popup
          const popup = document.createElement('div');
          const overlay = document.createElement('div');

          // 2. Add various classes to the popup and overlay
          Helpers.addClass(popup, 'popup-box');
          Helpers.addClass(popup, 'popup-box--progressive-profile');
          Helpers.addClass(overlay, 'overlay');
          Helpers.addClass(popup, this.visibleClassName);
          Helpers.addClass(overlay, this.visibleClassName);
          Helpers.addClass(document.getElementsByTagName('html')[0], 'has-popup-box');

          // 3. Add some JavaScript hooks to the popup and overlay
          popup.setAttribute('data-js', 'popup-box');
          overlay.setAttribute('data-js', 'overlay');

          // 4. Populate the popup with the contents of `url`
          popup.innerHTML = resp;

          // 5. Add event listeners to the close selector
          Helpers.forEach(popup.querySelectorAll(this.config.closeSelector), (el) => {
            el.addEventListener('click', this.hide);
          });

          // 6. Add event listener to the overlay so it closes the popup box on click
          overlay.addEventListener('click', this.hide);

          // 7. Add event listener for toggling additional fields
          this.toggleAdditionalFields(popup);

          // 8. Add event listeners to skip to download
          Helpers.forEach(popup.querySelectorAll(this.config.skipSelector), (el) => {
            el.addEventListener('click', (e) => {
              e.preventDefault();

              const downloadUrl = e.target.href;
              this.skipToDownload(
                downloadUrl,
                e.target.getAttribute('data-skip-title'),
                e.target.getAttribute('data-skip-message'),
                e.target.getAttribute('data-skip-link'),
              );
            });
          });

          // 9. Append the popup and the overlay to the body
          document.getElementsByTagName('body')[0].appendChild(popup);
          document.getElementsByTagName('body')[0].appendChild(overlay);

          // 10. Add a hidden field to the form that contains the JSON response
          const jsonField = document.createElement('input');
          jsonField.setAttribute('type', 'hidden');
          jsonField.setAttribute('name', 'json');
          jsonField.value = rawJson;

          popup.querySelector('form button').insertAdjacentHTML('beforebegin', jsonField.outerHTML);
        });
      } else {
        // 6. Otherwise, just start the download

        // 1. Create an iframe on the page to force the browser to download the file
        const iframe = document.createElement('iframe');
        iframe.src = url;
        iframe.style.display = 'none';
        document.querySelector('body').appendChild(iframe);

        window.gtmPageView(url, 'Download Success', 'show');
      }
    });
  }

  toggleAdditionalFields(popupBox) {
    const toggleButton = popupBox.querySelector('[data-js="additional-fields-toggle"]');

    if (toggleButton) {
      toggleButton.addEventListener('click', function _(e) {
        const button = this;

        // prevent some default behaviour
        e.preventDefault();
        e.stopPropagation();

        button.parentNode.parentNode.removeChild(button.parentNode);

        // show hidden fields
        Helpers.forEach(document.querySelectorAll('.field--toggleable'), (el) => {
          el.setAttribute('data-state', 'visible');
        });
      });
    }
  }

  skipToDownload(downloadUrl, skipTitle, skipMessage, linkText) {
    let title;
    let intro;
    let form;

    if (document.querySelector('.progressive-profiling--no-js')) {
      title = document.querySelector('.post__header .post__title');
      intro = document.querySelector('.primary-content > p');
      form = document.querySelector('form#edit_profile');
    } else {
      title = document.querySelector('.popup-box__title h3');
      intro = document.querySelector('.popup-box__title p');
      form = document.querySelector('form#edit_profile');
    }

    if (title) {
      title.innerHTML = skipTitle;
    }

    if (intro) {
      intro.innerHTML = `${skipMessage} <a href="${downloadUrl}">${linkText}</a>.`;
    }

    if (form) {
      form.parentElement.removeChild(form);
    }

    const links = document.querySelectorAll('.popup-form__link-btn');

    Array.prototype.forEach.call(links, (el) => {
      el.parentElement.removeChild(el);
    });

    window.gtmPageView(downloadUrl, 'Download Success', 'skipToDownload');
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = downloadUrl;
    document.querySelector('body').appendChild(iframe);
  }

  hide() {
    // select the popup box and overlay
    const popupBox = document.querySelector('[data-js="popup-box"]');
    const overlay = document.querySelector('[data-js="overlay"]');
    const theBody = document.querySelector('html');

    if (!(popupBox && overlay && theBody)) {
      return;
    }

    // remove the popup box and overlay
    popupBox.parentNode.removeChild(popupBox);
    overlay.parentNode.removeChild(overlay);
    if (theBody.classList) {
      theBody.classList.remove('has-popup-box');
    } else {
      let theClassName = theBody.getAttribute('class');
      theClassName = theClassName.replace(/(\s|^)has-popup-box(\s|$)/, ' ');
      theBody.setAttribute('class', theClassName);
    }
  }
}

export default ProgressiveProfiling;
