import PopupBox from './popup-box';

class Webinars {
  constructor() {
    this.constructor.applyPopups();
  }

  static applyPopups() {
    const buttons = document.querySelectorAll('div[data-webinar-detail]');
    for (let i = 0; i < buttons.length; i += 1) {
      const id = buttons[i].getAttribute('data-webinar-detail');
      (new PopupBox()).init({
        template: `/webinars/${id}`,
        selector: `[data-webinar-detail='${id}']`,
        styleClasses: 'popup-box--webinar-detail',
      });
    }
  }
}

export default Webinars;
