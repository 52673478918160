import Helpers from '../lib/helpers';

class ExternalContacts {
  constructor() {
    const map = document.querySelector('[data-regions]');

    if (map) {
      this.buildMap(map);
    }
  }

  buildMap(map) {
    // 1. Grab the regions from the map
    const regions = map.querySelectorAll('[data-region-id]');
    // console.log("regions = ", regions);
    if (regions.length > 0) {
      this.buildRegions(regions);
    }
  }

  /* eslint class-methods-use-this: "off" */
  buildRegions(regions) {
    // 2. Loop through all the regions
    Helpers.forEach(regions, (region, i) => {
      // 1. Add an event listener to each region on click
      region.addEventListener('click', () => {
        const regionId = region.getAttribute('data-region-id');

        // 1. Unselect all regions first
        Helpers.forEach(regions, (reg) => {
          reg.setAttribute('class', '');
        });

        // 2. Add an active class to the current region
        region.setAttribute('class', 'active');

        // 3. Find the corresponding table and show it and hide others
        const dataTable = document.querySelectorAll('[data-region]');
        Helpers.forEach(dataTable, (el) => {
          if (el.getAttribute('data-region') === regionId) {
            const contactIntro = document.querySelector('.external-contacts > p');
            if (contactIntro != null) {
              contactIntro.style.display = 'none';
            }
            el.style.display = 'inline-block';
          } else {
            el.style.display = 'none';
          }
        });
      });
    });
  }
}

export default ExternalContacts;
