import Helpers from '../lib/helpers';

class StickyHeader {
  constructor() {
    window.addEventListener('load', this.stickyHeader);
    window.addEventListener('resize', this.stickyHeader);
    window.addEventListener('scroll', this.stickyHeader);
  }

  /* eslint class-methods-use-this: "off" */
  stickyHeader() {
    // 1. Get the current viewport width
    const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

    if (document.querySelector('[data-preview]')) {
      Helpers.addClass(document.querySelector('body'), 'has-preview-banner');
    }

    // 2. If the browser's viewport is greater than 559px
    if (vw > 959) {
      // 1. Grab some important nodes
      const header = document.querySelector('.js-page-header');
      const navPrimary = document.querySelector('.js-primary-navigation');
      const searchBox = document.querySelector('[data-js="search"]');
      const previewBanner = document.querySelector('[data-preview]');
      let navIsVisible = false;

      // 2. Get the current scroll position
      const scroll = window.scrollY || window.pageYOffset;
      let scrollLength;

      // 3. Store some default heights for other elements
      let SearchBoxHeight = 0;
      let previewBannerHeight = 0;

      // 4. Redefine the height of the SearchBarHeight
      if (searchBox) {
        SearchBoxHeight = searchBox.clientHeight;
      }

      if (previewBanner) {
        previewBannerHeight = previewBanner.clientHeight;
      }

      // 5. Redefine scrollLength to take the SearchBoxHeight into account
      scrollLength = 162 + SearchBoxHeight + previewBannerHeight;

      // 6. If the current scroll position is greater than or equal to scrollLength
      if (scroll >= scrollLength) {
        // 1. Define the sticky nav as visible
        navIsVisible = true;

        // 2. Add an 'is-sticky' class to elements that need it
        Helpers.addClass(header, 'is-sticky');
        Helpers.addClass(navPrimary, 'is-sticky');
        if (searchBox) {
          Helpers.addClass(searchBox, 'is-sticky');
        }
        if (previewBanner) {
          Helpers.addClass(previewBanner, 'is-sticky');
        }

        // 3. Add a class to the body to imply the sticky nav is active
        Helpers.addClass(document.querySelector('body'), 'has-sticky-nav');

        // 4. Move the navigation inside the header if it's not already
        if (!header.querySelector('.navigation-div')) {
          // 1. Create a container that we can put the navigation inside
          const tmpNode = document.createElement('div');
          Helpers.addClass(tmpNode, 'navigation-div');
          tmpNode.appendChild(navPrimary.cloneNode(true));

          // 2. Insert the navigation into the sticky header
          document.querySelector('.secondary-nav').parentNode.insertBefore(tmpNode, document.querySelector('.secondary-nav'));
          let navDiv = document.querySelector('.page-header .primary-navigation').parentNode;
        }

      // 7. Otherwise, if the scroll position is less than scrollLength
      } else {
        // 1. Define the sticky nav as hidden
        navIsVisible = false;

        // 2. Remove the 'is-sticky' class from elements that need it
        Helpers.removeClass(header, 'is-sticky');
        Helpers.removeClass(navPrimary, 'is-sticky');
        if (searchBox) {
          Helpers.removeClass(searchBox, 'is-sticky');
        }
        if (previewBanner) {
          Helpers.removeClass(previewBanner, 'is-sticky');
        }

        // 3. Remove the class from the body that implies the sticky nav is active
        Helpers.removeClass(document.querySelector('body'), 'has-sticky-nav');

        // 4. Remove the cloned navigation
        if (header.querySelector('.navigation-div')) {
          Helpers.remove(header.querySelector('.navigation-div'));
        }
      }
    }
  }
}

export default StickyHeader;
