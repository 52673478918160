class TrimSummaryContents {
  // This is used to trim the summary contents for the orange rollovers used in some scenarios

  constructor() {
    this.constructor.run();
    window.addEventListener('resize', this.constructor.run);
  }

  static run() {
    const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

    if (document.querySelector('.summary--small')) {
      let trimLength = 150;
      let textToTruncate;

      if (vw >= 960 && vw < 1200) {
        trimLength = 70;
      } else if (vw >= 1200) {
        trimLength = 150;
      }

      const smallSummaries = document.querySelectorAll('.summary--small');
      smallSummaries.forEach((element) => {
        const el = element;
        textToTruncate = el.getAttribute('data-truncate');

        if (textToTruncate !== null) {
          el.querySelector('.js-truncate').innerHTML = TrimSummaryContents.truncate(textToTruncate, trimLength);
        }
      });
    }
  }

  static truncate(text, length) {
    if (text.length > length) {
      return `${text.substring(0, length).trim()}&hellip;`;
    }

    return text;
  }
}

export default TrimSummaryContents;
