import SmoothScroll from 'smooth-scroll';

class Scrolling {
  constructor() {
    const smoothScroll = new SmoothScroll('a[href*="#"]', { offset: 100 });

    // Global function used by many success partials.
    window.scrollPageAfterFormSubmission = () => {
      const message = document.querySelector('.message-box');
      smoothScroll.animateScroll(message, {}, { offset: 100, updateURL: false });
    };

    // Scroll the target element into view to escape the sticky header.
    // If didn't have sticky header the default browser behaviour would be fine.
    if (window.location.hash) {
      const targetElement = document.querySelector(window.location.hash);
      if (targetElement) {
        smoothScroll.animateScroll(targetElement, {}, {});
      }
    }
  }
}

export default Scrolling;
