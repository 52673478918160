class ChatButton {
  constructor() {
    if (window.showChat) {
      window.addEventListener('load', this.constructor.show);
      window.addEventListener('scroll', this.constructor.hide);
    }
  }

  static show() {
    const chatButton = document.querySelector('#divC4AWButton');

    chatButton.style.opacity = 1;
    chatButton.style.bottom = 0;
  }

  static hide() {
    const chatButton = document.querySelector('#divC4AWButton');

    chatButton.style.opacity = 1;
    chatButton.style.bottom = 0;
  }
}

export default ChatButton;
