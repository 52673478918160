import Helpers from '../../javascripts/lib/helpers';

class SearchBox {
  constructor() {
    this.visibleClassName = 'is-visible';

    // setup some config items that can be overwritten later
    this.config = {
      selector: '[data-js="search-btn"]', // the selector that should kick off the overlay
    };

    this.init();
  }

  init() {
    // attach the event listener to all instances of the selector
    const triggers = document.querySelectorAll(this.config.selector);
    Helpers.forEach(triggers, (el, i) => {
      el.addEventListener('click', (e) => {
        // prevent some default behaviour
        e.preventDefault();
        e.stopPropagation();

        if (document.querySelector('.search.is-visible')) {
          this.hide();
        } else {
          this.show();
        }
      });
    });
  }

  show() {
    const searchBox = document.querySelector('[data-js="search"]');
    Helpers.addClass(searchBox, this.visibleClassName);
    searchBox.setAttribute('aria-expanded', 'true');
    Helpers.addClass(document.querySelector('body'), 'has-search');
    searchBox.querySelector('input').focus();
  }

  hide() {
    const searchBox = document.querySelector('[data-js="search"]');
    Helpers.removeClass(searchBox, this.visibleClassName);
    searchBox.setAttribute('aria-expanded', 'false');
    Helpers.removeClass(document.querySelector('body'), 'has-search');
    searchBox.querySelector('input').blur();
  }
}

export default SearchBox;
