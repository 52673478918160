class WindpostForm {
  constructor() {
    const theButton = document.querySelector('button[type="button"]');
    const itemToClone = document.querySelector('[data-js="windpost"]');

    theButton.addEventListener('click', (e) => {
      const existingItemCount = document.querySelectorAll('[data-js="windpost"]').length;
      const clonedItem = itemToClone.cloneNode(true);
      const inner = clonedItem.innerHTML;
      const newInner = inner.replace(/\[0\]/g, '[' + existingItemCount + ']')
                            .replace(/_0_/g, '_' + existingItemCount + '_');
      clonedItem.innerHTML = newInner;
      theButton.parentNode.insertBefore(clonedItem, theButton);
      e.preventDefault();
    });
  }
}

export default WindpostForm;
