import Helpers from '../../javascripts/lib/helpers';
import Flickity from 'flickity';

class GallerySlider {
  constructor() {
    // Handle the lazyloading of gallery slides
    window.lazySizesConfig = window.lazySizesConfig || {};
    window.lazySizesConfig.loadMode = 1;

    window.lazySizesConfig.expand = 222;
    window.lazySizesConfig.expFactor = 1.6;

    // If we try to print the page, show images:
    window.lazySizesConfig.preloadAfterLoad = true;

    window.Flickity = Flickity;

    var galleries = document.querySelectorAll('[data-gallery]');
    Helpers.forEach(galleries, function (el, i) {

      setTimeout(function () {
        var gallery = new Flickity (el, {
          cellSelector: 'figure',
          wrapAround: true,
          pageDots: false,
          imagesLoaded: true
        }, 250);
      });

    });

    // Handle the lazyloading of gallery slides
    var oldFlickityCreate = window.Flickity.prototype._create;

    window.Flickity.prototype._create = function(){
      var that = this;
      if(this.element.addEventListener){
        this.element.addEventListener('load', function(){
          that.onresize();
        }, true);
      }
      this._create = oldFlickityCreate;
      return oldFlickityCreate.apply(this, arguments);
    };
  }
}

export default GallerySlider;
