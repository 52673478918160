class FeedbackTab {
  constructor() {
    if (document.querySelector('[data-feedback-form]')) {
      window.addEventListener('load', this.constructor.feedbackTab);
      window.addEventListener('scroll', this.constructor.hideFeedBackTab);
    }
  }

  static feedbackTab() {
    const feedbackButton = document.querySelector('[data-feedback-form]');
    const chatButton = document.querySelector('#divC4AWButton');
    const marginRight = chatButton != null ? chatButton.clientWidth + 10 : 0;

    feedbackButton.style.right = `${marginRight}px`;
    feedbackButton.style.opacity = 1;

    feedbackButton.style.bottom = 0;
  }

  static hideFeedBackTab() {
    const feedbackButton = document.querySelector('[data-feedback-form]');
    feedbackButton.style.bottom = 0;
  }
}

export default FeedbackTab;
