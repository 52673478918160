import Helpers from '../lib/helpers';

class BimObjects {
  constructor(progressiveProfiling) {
    const bimToggles = document.querySelectorAll('[data-js="bim-table-toggle"]');
    Helpers.forEach(bimToggles, (el) => {
      el.addEventListener('click', () => {
        this.toggleDropdown(el);
      });
    });

    Helpers.forEach(document.querySelectorAll('#bim_download'), (el) => {
      const submit = el.querySelector('input[type="submit"]');

      submit.addEventListener('click', (e) => {
        e.preventDefault();

        if (document.querySelectorAll('#bim_download input[type="checkbox"]:checked').length !== 0) {
          this.constructor.ajaxPost(document.querySelector('#bim_download'), (response) => {
            progressiveProfiling.show(response);
          });
        }
      });
    });
  }

  toggleDropdown(el) {
    let nextTr = el.parentNode.nextSibling;
    if (nextTr && this.constructor.isWhitespace(nextTr)) {
      nextTr = nextTr.nextSibling;
    }

    if (!Helpers.hasClass(el.parentNode, 'is-active')) {
      if (el.parentNode.classList) {
        el.parentNode.classList.add('is-active');
        nextTr.classList.add('is-visible');
      } else {
        el.parentNode.className += ' is-active';
        nextTr.className += ' is-visible';
      }
    } else {
      /* eslint no-lonely-if: "off" */
      if (el.parentNode.classList) {
        el.parentNode.classList.remove('is-active');
        nextTr.classList.remove('is-visible');
      } else {
        el.parentNode.className.replace(/(\s|^)is-active(\s|$)/, ' ');
        nextTr.className.replace(/(\s|^)is-visible(\s|$)/, ' ');
      }
    }
  }

  static isWhitespace(node) {
    return node.nodeType == 3 && /^\s*$/.test(node.data);
  }

  /* eslint arrow-body-style: "off" */
  static ajaxPost(form, callback) {
    const url = form.action;
    const xhr = new XMLHttpRequest();

    const params = [].filter.call(form.elements, (el) => {
      // Filter out checkboxes/radios which aren't checked.
      return typeof (el.checked) === 'undefined' || el.checked;
    })
      .map((el) => {
        // Map each field into a name=value string
        return `${encodeURIComponent(el.name)}=${encodeURIComponent(el.value)}`;
      })
      .join('&'); // Then join all the strings by &

    xhr.onreadystatechange = function _() {
      // If the request is completed
      if (this.readyState === 4) {
        // If the request was successful
        if (callback && typeof (callback) === 'function') {
          callback(xhr.responseText);
        }
      }
    };

    xhr.open('POST', url);

    // Changed from application/x-form-urlencoded to application/x-form-urlencoded
    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    xhr.setRequestHeader('X-CSRF-Token', Helpers.getContentByMetaTagName('csrf-token'));
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

    // Send the request!
    xhr.send(params);
  }
}

export default BimObjects;
