import Helpers from '../lib/helpers';

class PopupBox {
  constructor() {
    this.visibleClassName = 'is-visible';
    // popupTitle, popupText, shareTitle, shareUrl  // not required

    // setup some config items that can be overwritten later
    this.config = {
      template: '/templates/popup-box.html',
      selector: '[data-js="popup-btn"]', // the selector that should kick off the overlay
      closeSelector: '[data-js="popup-box-close"]', // the selector that should close the overlay
      styleClasses: '', // Additional classes for styling hooks
    };
  }

  init(options) {
    // override the default config
    this.config = this.extend(this.config, options || {});

    const { config } = this;

    const popupTemplate = config.template;
    const popupClass = config.styleClasses;

    // attach the event listener to all instances of the selector
    const triggers = document.querySelectorAll(config.selector);
    Helpers.forEach(triggers, (el) => {
      el.addEventListener('click', (e) => {
        // prevent some default behaviour
        e.preventDefault();
        e.stopPropagation();

        // store the url and title in a variable
        const shareTitle = el.getAttribute('data-share-title');
        const shareUrl = el.getAttribute('data-share-url');
        const popupTitle = el.getAttribute('data-popup-title');
        const popupText = el.getAttribute('data-popup-text');

        // show the popup box
        this.show(shareTitle, shareUrl, popupTitle, popupText, popupTemplate, popupClass);
      });
    });

    // close the popup box when user hits `esc` key
    document.onkeydown = (evt) => {
      evt = evt || window.event;
      if (evt.keyCode == 27) {
        this.hide();
      }
    };
  }

  // Show the popup box and overlay
  /* eslint class-methods-use-this: "off" */
  show(shareTitle, shareUrl, popupTitle, popupText, template, css) {
    // grab the contents of the popup overlay
    Helpers.getFileContents(template, (response) => {
      // create elements for the popup box and the overlay
      const popupBox = document.createElement('div');
      const overlay = document.createElement('div');
      const theBody = document.querySelector('html');

      // add classes to each of the new elements
      if (popupBox.classList && overlay.classList && theBody.classList) {
        popupBox.classList.add('popup-box');
        overlay.classList.add('overlay');
        if (css !== '') {
          popupBox.classList.add(css);
        }
        theBody.classList.add('has-popup-box');
      } else {
        popupBox.className += ' popup-box';
        overlay.className += ' overlay';
        if (css !== '') {
          popupBox.className += ` ${css}`;
        }
        theBody.className += ' has-popup-box';
      }

      // add some js-hooks to the data-attribute
      popupBox.setAttribute('data-js', 'popup-box');
      overlay.setAttribute('data-js', 'overlay');

      // append the contents of the template to the newly created div
      popupBox.innerHTML += response;

      // append both divs to the end of the body
      document.body.appendChild(popupBox);
      document.body.appendChild(overlay);

      // add visible classes to each of the new elements
      if (popupBox.classList && overlay.classList) {
        popupBox.classList.add(this.visibleClassName);
        overlay.classList.add(this.visibleClassName);
      } else {
        popupBox.className += ` ${this.visibleClassName}`;
        overlay.className += ` ${this.visibleClassName}`;
      }

      // set data-attributes on the popup box
      popupBox.setAttribute('data-share-title', shareTitle);
      popupBox.setAttribute('data-share-url', shareUrl);

      // add event listeners to the close selector
      Helpers.forEach(document.querySelectorAll(this.config.closeSelector), (el) => {
        el.addEventListener('click', this.hide);
      });

      // add event listener to the overlay so it closes the popup box on click
      overlay.addEventListener('click', this.hide);

      const languageButton = document.querySelector('#language-button');

      if (languageButton) {
        languageButton.addEventListener('click', this.navigate);
      }

      if (popupTitle) {
        popupBox.querySelector('h3').innerHTML = popupTitle;
      }

      if (popupText) {
        popupBox.querySelector('p').innerHTML = popupText;
      }

      // add event listeners to the social icons
      this.linkpopups(shareTitle, shareUrl);
      this.bimpopups();
    });
  }

  /**
   * Hide the popup box and overlay
   */
  hide() {
    // select the popup box and overlay
    const popupBox = document.querySelector('[data-js="popup-box"]');
    const overlay = document.querySelector('[data-js="overlay"]');
    const theBody = document.querySelector('html');

    // remove the popup box and overlay
    popupBox.parentNode.removeChild(popupBox);
    overlay.parentNode.removeChild(overlay);
    if (theBody.classList) {
      theBody.classList.remove('has-popup-box');
    } else {
      let theClassName = theBody.getAttribute('class');
      theClassName = theClassName.replace(/(\s|^)has-popup-box(\s|$)/, ' ');
      theBody.setAttribute('class', theClassName);
    }
  }

  static hasClass(target, className) {
    return new RegExp('(\\s|^)' + className + '(\\s|$)').test(target.className);
  }

  static isWhitespace(node) {
    return node.nodeType == 3 && /^\s*$/.test(node.data);
  }

  /**
   * Navigate to another page using the value of the drop down
   *
   * @private
   * @param {object} event - Source event
   */
  navigate(event) {
    event.preventDefault();
    event.stopPropagation();
    const languageSelect = document.getElementById('language');
    window.location.href = languageSelect.options[languageSelect.selectedIndex].value;
  }

  /**
   * Attach all event listeners to the social popup icons
   */
  linkpopups(title, url) {
    // loop through each link and attach the event listener
    const links = document.querySelectorAll('[data-js="social-link"]');
    Helpers.forEach(links, (el) => {
      const platform = el.getAttribute('data-share-platform');
      el.addEventListener('click', () => {
        this.openLink(title, url, platform);
      });
    });
  }

  openLink(title, url, platform) {
    // open the window
    window.open(
`https://api.addthis.com/oexchange/0.8/forward/${platform}/offer?url=\
${window.location.host}${encodeURIComponent(url)}&title=\
${encodeURIComponent(title)}&pco=tbxnj-1.0`,
      encodeURIComponent(title),
      'width=600,height=400',
    );
  }

  bimpopups() {
    const bimToggles = document.querySelectorAll('[data-js="bim-table-toggle"]');
    Helpers.forEach(bimToggles, (el) => {
      el.addEventListener('click', () => {
        this.toggleDropdown(el);
      });
    });
  }

  toggleDropdown(el) {
    let nextTr = el.parentNode.nextSibling;
    if (nextTr && this.constructor.isWhitespace(nextTr)) {
      nextTr = nextTr.nextSibling;
    }

    if (!this.constructor.hasClass(el.parentNode, 'is-active')) {
      if (el.parentNode.classList) {
        el.parentNode.classList.add('is-active');
        nextTr.classList.add('is-visible');
      } else {
        el.parentNode.className += ' is-active';
        nextTr.className += ' is-visible';
      }
    } else {
      if (el.parentNode.classList) {
        el.parentNode.classList.remove('is-active');
        nextTr.classList.remove('is-visible');
      } else {
        el.parentNode.className.replace(/(\s|^)is-active(\s|$)/, ' ');
        nextTr.className.replace(/(\s|^)is-visible(\s|$)/, ' ');
      }
    }
  }

  // Merges two objects together
  extend() {
    // Variables
    var extended = {},
        deep = false,
        i = 0,
        length = arguments.length;

    // Check if a deep merge
    if (Object.prototype.toString.call(arguments[0]) === '[object Boolean]') {
      deep = arguments[0];
      i++;
    }

    // Merge the object into the extended object
    var merge = function (obj) {
      for (var prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
          // If deep merge and property is an object, merge properties
          if (deep && Object.prototype.toString.call(obj[prop]) === '[object Object]') {
            extended[prop] = extend(true, extended[prop], obj[prop]);
          } else {
            extended[prop] = obj[prop];
          }
        }
      }
    };

    // Loop through each object and conduct a merge
    for (; i < length; i++) {
      var obj = arguments[i];
      merge(obj);
    }

    return extended;
  }
}

export default PopupBox;
